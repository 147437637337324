$silver: #E5E5E5;
$silverLight: #F1F1F1;
$footerBg: #332F30;

$primary: #ED1C23;

$h1-font-size: 1.4rem;
$h2-font-size: 1.2rem;
$h3-font-size: 1rem;

@import "./node_modules/bootstrap/scss/bootstrap";

h2{
  font-weight: bold;
}

ul.redsquare{
  li {
    list-style-type: square;
    line-height: 0.7rem;
    font-weight: bold;
    color: $primary;
    p {
      color: #292929;
    }
  }
}

p{
  color: #292929;
}
ul > li{
  font-size: 0.85rem;
  line-height: 1.6rem;
}

.logo{
  height: 75px;
}

.silver{
  background: $silver;
  font-size: 0.85rem;
}
.silver-light{
  background: $silverLight;
}
.white{
  background: #ffffff;
}
.footer{
  background: $footerBg;
}

.download-container{
  img{
    border: 5px solid $primary;
  }
}
.caso-preview{
  width: 30%;
}
.more-info{
  font-size: 0.8rem;
}
.placeholder{
  background: url("../build/images/bg-placeholder.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 700px;
  width: 100%;
  position: absolute;
  left: 30%;
  top: 10%;
}
.navbar{
  ul{
    li{
      a.nav-link {
        color: #000000;
        font-weight: bold;

        &:hover, &.active {
          color: $primary;
        }
      }
    }
  }
}
.min-vh{
  min-height: 100vh;
  padding-bottom: 10rem !important;
}

.opacity-08{
  opacity: 0.8;
}

.overeno-body{
  .nadpis{
    font-size: 1.4rem;
    display: block;
  }
}

.inzerujte{
  h2{
    font-size: 1rem;
    margin-bottom: 0px !important;
  }
}

.inzerujte-prefix{
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3rem;
  .red{
    color: $primary;
  }
}

.reference{
  img{
    margin-right: 26px;
    margin-bottom: 27px;
  }
  .float-left{
    margin-bottom: 0px;
    margin-right: 30px;
  }
}
.smaller{
  font-size: 0.8rem;
}
.kontakt{
  .strong-smaller{
    font-size: 0.9rem;
  }
}

.btn{
  border-radius: 0px;
}

.svah{
  h3{
    font-weight: bold;
  }
  img{
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.75);
  }
}
.form-control{
  font-size: 0.85rem;
}


@media (min-width: 768px) {
  .navbar {
    position: absolute;
    bottom: 0px;
  }
}

@media (max-width: 992px) {
  .min-vh{
    min-height: auto;
    padding-bottom: 3rem !important;
  }
  .reference{
    img {
      margin-right: 13px;
      margin-left: 13px;
    }
    .float-left{
      margin-right: 19px;
    }
  }
  .reference{
    h3{
      color: #d21117;
      font-size: 1.3rem;
    }
  }


  .item .slider-text{
    padding-top: 20px !important;
  }
}

@media (max-width: 768px) {
  .reference {
    .float-left{
      margin-bottom: 26px;
    }
  }
}

#owl-car .item img{
  display: block;
  width: 100%;
  height: auto;
}
.owl-prev{
  position: absolute;
  left: 40px;
  top: 50%;
  z-index: 999999;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 50px solid #cbcbcb;
  border-bottom: 25px solid transparent;
  margin: 0px !important; padding: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  background: none !important;
  font-size: 0px !important;
}
.owl-next{
  position: absolute;
  right: 40px;
  top: 50%;
  z-index: 999999;
  border-top: 25px solid transparent;
  border-left: 50px solid #cbcbcb;
  border-bottom: 25px solid transparent;
  margin: 0px !important; padding: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  background: none !important;
  font-size: 0px !important;
}
.owl-pagination {
  position: absolute;
  bottom: 22px;
  width: 100%;
}
.owl-theme .owl-controls{
  margin-top: 0px !important;
}
#owl-car, .slide, .item{
  min-height: 77vh;
}
.item {
  background: url("./images/carousel1.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}

.item.item-1{
  background-image: url("./images/carousel1.jpg");
}
.item.item-2{
  background-image: url("./images/carousel2.jpg");
}
.item.item-3{
  background-image: url("./images/carousel3.jpg");
}
.item.item-4{
  background-image: url("./images/carousel4.jpg");
}
.item.item-1-m{
  background-image: url("./images/carousel1-m.png");
}


.item{
  .slider-text{
    font-size: 2.3125rem;
    line-height: 117%;
    color: white;
    padding-top: 180px;
    font-family: 'NunitoSans-ExtraBold';
    height: 77vh;
    text-decoration: none !important;
    &.black{
      color: #222222;
    }
  }
}

.item.item-1-m {
  .slider-text{
    font-size: 1.9125rem;
  }
}

.null-eight{
  font-size: 0.8rem;
}







/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content-img, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content-img {
    width: 100%;
  }
}




.ball{
  text-align: center;
  vertical-align: middle;
  font-size: 500%;
  position: relative;
  color: #ffffff;
  strong{
    color: #000000;
    font-size: 26px;
    display: block;
  }
  .angle-45{
    transform: rotate(270deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(270deg);
    /* Firefox */
    -moz-transform: rotate(270deg);
    /* IE */
    -ms-transform: rotate(270deg);
    /* Opera */
    -o-transform: rotate(270deg);
    display: block;
    ///* Internet Explorer */
    //filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
}

h2.bigone{
  font-size: 36px;
  text-align: left;
  margin-bottom: 50px;
  span{
    color: #ED536C;
  }
}
.form-label{
  width: 20%;
  text-align: left;
}
.form-input{
  width: 80%;
  border: none;
  border-radius: 0px;
}
.img-right-form{
  width: 400px;
}

.ukazka-title{
  font-size: 24px;
}

.font-size-14{
  font-size: 14px;
}

//input.cs[type=checkbox] {
//  position: relative;
//  cursor: pointer;
//}
//input.cs[type=checkbox]:before {
//  content: "";
//  display: block;
//  position: absolute;
//  width: 16px;
//  height: 16px;
//  top: 0;
//  left: 0;
//  //border: 2px solid #555555;
//  //border-radius: 3px;
//  background-color: white;
//}
//input.cs[type=checkbox]:checked:after {
//  content: "";
//  display: block;
//  width: 5px;
//  height: 10px;
//  border: none;
//  //border-width: 0 2px 2px 0;
//  -webkit-transform: rotate(45deg);
//  -ms-transform: rotate(45deg);
//  transform: rotate(45deg);
//  position: absolute;
//  top: 2px;
//  left: 6px;
//}